import React from "react";
import { HiMenuAlt3 } from "react-icons/hi";
const Navbar = () => {
  return (
    <div>
      <h1>Book Now!</h1>
      <HiMenuAlt3 size={25} />
      <div>
        <ul>
          <li>Book Now</li>
          <li>Contact Us</li>
          <li>Questions</li>
          <li>Events Weve Done</li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
